<template>
  <div id="password-main">
    <h1 class="password-title">账号管理</h1>
    <n-button type="info" color="#744dfe" class="password-btn-return" @click="handleBack(true)">返回</n-button>
    <div class="password-bor"></div>
    <div class="password-cont">
      <div class="password-item1">
        <h1 class="password-title1">登录密码管理</h1>
        <n-button type="info" color="#744dfe" class="password-btn" @click="passwordPopup(true)">点击重置</n-button>
        <div class="password-bor1"></div>
      </div>
    </div>

    <!-- 重置密码弹窗 -->
    <div id="StoragePopup">
      <n-modal
        class="custom-card"
        v-model:show="isPopup"
        preset="card"
        :style="bodyStyle"
        title="重置密码"
        size="huge"
        :bordered="false"
        :segmented="segmented"
      >
        <div class="password-popup-cont">
          <div class="password-inputs">
          <n-input v-model:value="password1" placeholder="设置6至20位登录密码" @blur="contrastPassword(password1)" />
          </div>
          <div class="password-inputs">
          <n-input v-model:value="password2" placeholder="请再次输入登录密码" @blur="contrastPassword(password2)" />
          </div>
        </div>
        <div class="password-btns">
          <NSpace>
            <NButton type="info" @click="modifyPassword">确定</NButton>
          </NSpace>
        </div>
      </n-modal>
    </div>
    <!-- 重置密码弹窗结束 -->

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import { PostAssignmentReset } from "@/api/admin-home.js"; // 详情 编辑 地址
import { useRoute, useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";

const { SUCCESS } = resStatusEnum;
const loading = ref(false);

const route = useRoute();
const router = useRouter();
const message = useMessage();
const isPopup = ref(false)
const password1 = ref(null)
const password2 = ref(null)
const bodyStyle = ref({
  width: "500px",
});
const segmented = ref({
  content: "soft",
  footer: "soft",
});
const isTrue = ref(false)  // 重置密码状态
// 密码重置
function modifyPassword(){
  if(isTrue.value){
    loading.value = true;
    let param = {
      "AdminModel[password]":password1.value,
      "AdminModel[re_password]":password2.value,
    }
    PostAssignmentReset(param)
      .then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.success(res.msg);
          handleBack()
        }else{
          message.info(res.msg);
        }
      })
    .catch((reject) => {});
  }else{
    message.info("验证错误");
  }
  
};

// 密码对比
function  contrastPassword(val) {
  let str = String(val).length
  if(str<6){
    message.info("密码不能少于6位");
    isTrue.value = false
  }else if(password2.value !=null&&password1.value !=null){
    if(password1.value != password2.value){
      message.info("两次密码输入不一致,请重新输入");
      isTrue.value = false
    }else{
      message.success("两次密码输入一致");
      isTrue.value = true
    }
  }
}

// 密码弹窗打开
function passwordPopup(is) {
  isPopup.value = is
}

 // 返回事件
const handleBack = () => {
  window.history.back()
};
</script>

<style scoped>
html,body{
  height:100%
}
#password-main {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  margin-top: 20px;
  height:780px
}
.password-title {
  color: #000;
  font-size: 18px;
  padding: 0px 0 20px 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #fff;
}
.password-bor{
  width: 100%;
  height: 1px;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
  position: absolute;
  left:0;
  right:0;
}
.password-cont{
  position: relative;
  top:22px
}
.password-item1{
  padding: 28px 20px;
}
.password-title1{
  color: #000;
  font-size: 16px;
  padding: 0px 0 20px 20px;
  padding-bottom: 0;
  margin: 0;
  margin-right: 50px;
  display: inline-block;
}
.password-bor1{
  width: 100%;
  height: 1px;
  margin-top: 28px;
  border-bottom: 1px solid #ddd;
}
.password-btn{
  width: 140px;
}
.password-inputs{
  padding: 20px 20px 10px 20px;
  width: 260px;
  margin:0 auto
}
.password-btns{
  padding-top: 30px;
  width: 260px;
  margin:0 auto
}
.password-btns .n-button{
  width: 260px;
  margin:0 auto
}
.password-btn-return{
  float: right;
  position: relative;
  top: -30px;
}
</style>